<template>
  <insurance-form />
</template>

<script>
  import InsuranceForm from './form'
  export default {
    components: { InsuranceForm },
  }
</script>
